.fret-numbers {
  display: flex;
  justify-content: space-between;
}

.fret-number {
  width: 30px;
  text-align: center;
  padding: 5px;
  margin: 0 5px;
  background-color: grey;
  color: white;
  border-radius: 3px;
}

.fret-number.hidden {
  display: none;
}

.fret-number.open {
  background-color: transparent;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.fret-numbers {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Full width of the fretboard */
  padding: 5px 23px; /* Padding above and below */
  box-sizing: border-box; /* Include padding and border in element's total width */
  z-index: 3; /* Ensure the fret numbers are above all */
}

.fret-number {
  width: 30px; /* Same width as frets */
  text-align: center;
  background: #939393; /* Dark background for the number */
  color: #fff; /* White text for contrast */
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 0.8rem;
  position: relative;
  z-index: 3; /* Ensure the number is above the string lines and frets */
}
@media only screen and (max-device-width: 866px) and (orientation: landscape) {

.fret-numbers {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0px;
    box-sizing: border-box;
    z-index: 3;
}
.fret-number.open{font-size:10px;}
.fret-number {
  width: 20px; /* Same width as frets */
  text-align: center;
  background: #939393; /* Dark background for the number */
  color: #fff; /* White text for contrast */
  border-radius: 3px;
  padding: 0px 0px;
  font-size: 0.8rem;
  position: relative;
  z-index: 3; /* Ensure the number is above the string lines and frets */
}
}