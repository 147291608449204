.playcontrols {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.upper-controls {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.scale-display, .settings-display, .metronome-display, .playnow-display, .playnote-display { /* Apply same style to scale, settings, and metronome displays */
  display: flex;
  align-items: center;
  margin-right: 10px;
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
  height: 60px;
}

.scale-info, .settings-display div, .metronome-display div { /* Apply font style to all display divs */
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
}

.settings-display div, .metronome-display div {
  margin-right: 5px;
}

.scale-positions {
  display: flex;
}

.position {
  width: 20px;
  height: 20px;
  background-color: #d3d3d3;
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-size: 14px;
  transition: background-color 0.3s;
}

.position.show-all {
  width: auto;
  padding: 0 10px;
}

.position:hover, .position.active{
  background-color: green;
  color: white;
}

.lower-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lower-controls button {
  padding: 10px;
  margin: 5px;
  border: none;
  background-color: #ccc;
  color: #333;
  cursor: pointer;
  line-height: 26px;
  transition: background-color 0.3s;
}

.lower-controls button.active {
  background-color: rgb(39, 156, 10); /* Green color for active state */
  color: white;
}

.lower-controls button:hover {
  background-color: rgb(39, 156, 10);
}

.lower-controls button img {
  padding: 0 10px 0 10px;
}

.settings-display, .metronome-display { /* Ensure both displays are column-based */
  flex-direction: column;
}

.settings-display div, .metronome-display div {
  margin-right: 0;
}

.key-info.highlight {
  background-color: yellow; /* or any other color you prefer for highlighting */
}
.playnote-display {
  flex-direction: column;

}
.playnow-display {
  flex-direction: column;
 
}

.playnote-display h4 {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.note-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 4px; /* Spacing between buttons */
  margin-bottom: 10px;
}

.note-buttons .position {
  width: 20px;
    height: 20px;
    background-color: #d3d3d3;
    margin: 0 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-size: 14px;
    transition: background-color 0.3s;
}

.note-buttons .position.active {
  background-color: #4caf50; /* Green color for active note */
  color: white;
}

.currently-playing {
  margin-top: 10px;
  font-size: 14px; /* Adjust the font size */
  font-weight: normal;
}

.playnow-display h4 {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.playnow-notes {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  font-size: 14px;
  font-weight: normal;
}

.playnow-notes .note {
  font-weight: normal;
}

.playnow-notes .note.recent-note {
  font-weight: bold;
  color: #000; /* Optional: change color to make it stand out */
}
@media only screen  and (max-device-width: 866px) and(orientation: landscape) {
  
  .playcontrols {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -60px;
  }
  .settings-display, 
  .scale-display {
    position: absolute;
    top: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    z-index: 150;
    background-color: #f0f0f0; /* Background color */
  }

  .popup-container {
    z-index: 300!important; /* Highest z-index to ensure it's on top of everything */
    position: relative; /* Ensure correct positioning */
  }
  .settings-display {
    left: 300px; /* Position the settings display to the left */
    top: -76px; /* Adjust the top margin */z-index: 28500; 

}
.settings-popup-overlay {
z-index:200;
}
.scale-display {
    left: 580px; /* Adjust this value based on your layout */
    top: -76px; /* Align it with the settings display */z-index: 28500; 
}
  .logo-container {
    z-index: 900; /* Slightly lower than the displays */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  }