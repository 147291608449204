.fretboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #2E2E2E; /* Dark brown background to mimic wood */
  padding: 15px 0; /* More padding at the top and bottom */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding and border in element's total width */
  position: relative; /* Ensure padding is within the fretboard */
}
.fretboard-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
}

.fretboard-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
}



.string {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  z-index: 2; /* Ensure string lines are on top */
}

/* Styling for the string lines with varying thickness */
.string::before {
  content: '';
  position: absolute;
  left: 1.5%;
  right: 0;
  top: 50%;
  border-top-style: solid;
  transform: translateY(-50%);
  border-top-color: white; /* Default color for strings */
  z-index: 1; /* Ensure string lines are below the note text */
}

/* Default string thickness when not reversed */
.string:nth-child(6)::before { /* High E string (thinnest) */
  border-top-width: 1px; 
}

.string:nth-child(5)::before { /* B string */
  border-top-width: 1.5px;
}

.string:nth-child(4)::before { /* G string */
  border-top-width: 2px;
}

.string:nth-child(3)::before { /* D string */
  border-top-width: 2.5px;
}

.string:nth-child(2)::before { /* A string */
  border-top-width: 3px;
}

.string:nth-child(1)::before { /* Low E string (thickest) */
  border-top-width: 3.5px;
}

/* Styling for reversed strings */
.reverse-strings .string:nth-child(6)::before { /* Low E string (thickest) */
  border-top-width: 3.5px !important;
}

.reverse-strings .string:nth-child(5)::before { /* A string */
  border-top-width: 3px;
}

.reverse-strings .string:nth-child(4)::before { /* D string */
  border-top-width: 2.5px;
}

.reverse-strings .string:nth-child(3)::before { /* G string */
  border-top-width: 2px;
}

.reverse-strings .string:nth-child(2)::before { /* B string */
  border-top-width: 1.5px;
}

.reverse-strings .string:nth-child(1)::before { /* High E string (thinnest) */
  border-top-width: 1px;
}
.playcontrols button{
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  float:left;
  width:150px;
}





/* Styling for fret markers */
.fret-marker {
  position: absolute;
  top: 50%;
  transform: translate(200%, -50%);
  z-index: 0; 
}

.fret-marker .dot {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  margin: 2px 0; 
}

.fret-marker.double .dot {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  margin: 100px 0;
}
.fret-marker.double .dot:nth-child(2) {
  top: calc(50% + 20px); 
}
.fret.highlighted {
  background-color: blue;
  color: white;
}

.fret.stop {
  background-color: red;
  color: white;
}

.open-note::before {
  background-color: orange !important;
  opacity: 1 !important;
}


@media only screen and (max-device-width: 866px) and (orientation: landscape) {
  .fretboard-container {
    flex-direction: row;
  }

  .fretboard {
    flex: 1;
    margin-right: 10px; width:100%;
  }
  .fret-marker{
  position: absolute;
  top: 50%;
  transform: translate(80%, -50%);
  z-index: 0;
  }
  
  .fret-marker .dot {
    width: 18px;
    height: 18px;
 
  }
  
  .fret-marker.double .dot {
    width: 18px;
    height: 18px;
 
  }
  .fret-marker.double .dot:nth-child(2) {
    top: calc(50% + 20px); 
  }
}
