
.metronome-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000b5;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}.metronome-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  background: white;
  justify-content: center;
  align-items: center;
  z-index: 1000;box-shadow: 0 2px 10px rgb(255 255 255 / 45%);
}
.metronome-popup h2 {
  margin-top: 0; color:black;
}

.metronome-popup label {
  display: flex;
  align-items: center;
}

.metronome-popup input[type="number"] {
  margin-left: 10px;
  width: 120px;
  height: 30px;
  font-size: 30px;
  position: relative;
  -moz-appearance: textfield;
  -webkit-appearance: none; 
  appearance: none;
  padding-right: 30px; 
  top: -20px;
}

.metronome-popup input[type="number"]::-webkit-outer-spin-button,
.metronome-popup input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Remove the default arrows in Chrome/Safari */
  margin: 0;
}

.metronome-popup input[type="number"]::after {
  content: '\25B2'; /* Unicode for up arrow */
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 12px;
  color: #000;
  cursor: pointer;
}

.metronome-popup input[type="number"]::before {
  content: '\25BC'; /* Unicode for down arrow */
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-size: 12px;
  color: #000;
  cursor: pointer;
}

.metronome-popup input[type="number"]:focus::-webkit-outer-spin-button,
.metronome-popup input[type="number"]:focus::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Remove the default arrows in Chrome/Safari */
}
button{width:100%;padding: 10px;
  border: none;
  background-color: #000000;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;}

div.metronome-body{margin-top:20px;}