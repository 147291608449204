@import './fonts.css';

.App {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
}

.logo-container {
  display: flex;
  align-items: center; /* Align all items (logo, heading, tagline) in the center vertically */
  justify-content: center; /* Center the logo and text horizontally */
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text elements to the start */ 
  padding-top:10px;
}

img.logo {
  max-width: 120px; /* Adjust size */
  vertical-align: middle; /* Align with text */
  margin-top: -24px;
  padding: 10px;
}

h1 {
  color: #fff;
  font-size: 60px; /* Adjusted size */
  line-height: 30px;
  font-family: 'Saira Condensed Medium', sans-serif;
  font-weight: normal;
  margin: 0; /* Remove margin to align with tagline */
}

h2 {
  font-size: 38px; /* Adjusted size */
  color: #ffffff; /* Softer color for the tagline */
  line-height: 46px;
  font-family: 'Saira Condensed Light', sans-serif;
  font-weight: 600;
  margin-top: 2px; /* Small space above the tagline */
  text-align: left;
}

.controls {
  display: flex;
  flex-direction: column;
  text-align: left;
  float: left;
  width:100%;
}

.controls label {
  margin: 10px 0;
}

.playcontrols-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
button {background-color:#000;}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* General styling for the rotate screen */
.rotate-screen {
  display: none; /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2e2e2e;
  color: white;
  text-align: center;
  z-index: 9999; /* Make sure it is above other content */
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Increase sizes for portrait mode */
.rotate-logo {
  max-width: 280px; /* Larger logo */
}

.rotate-screen h1 {
  font-size: 56px; /* Even larger h1 */
}

.rotate-screen h2 {
  font-size: 32px; line-height:38px;/* Even larger h2 */
}

.rotate-screen p {
  font-size: 28px; /* Larger message text */
}

/* Styling for the phone icon */
.rotate-icon {
  width: 120px; /* Adjust the width to make it smaller */
  height: 120px; /* Adjust the height to match the width */
  margin: 20px auto;
}
.rotate-animation {
  animation: rotate-pause 2s infinite ease-in-out; /* Updated animation */
}

/* Rotating animation with pause */
@keyframes rotate-pause {
  0% { transform: rotate(0deg); }
  45% { transform: rotate(90deg); }
  55% { transform: rotate(90deg); } /* Pause for 3 seconds */
  100% { transform: rotate(0deg); }
}

/* Styling for the message */
.rotate-screen p {
  font-size: 13px;
  margin-top: 10px;
  color: #cccccc;
  animation: blink 1.5s infinite; /* Add blinking animation */
}

/* Blinking animation */
@keyframes blink {
  0%, 50%, 100% {
    opacity: 1; /* Fully visible */
  }
  25%, 75% {
    opacity: 0; /* Invisible */
  }
}
.copy{color:#fff; padding-top:20px; font-size:12px;}
.install-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #009650;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}
.install-button:hover {
  background-color: #02cf36;
}
@media only screen and (orientation: portrait) {
  .controls-container,
  .app-content {
    display: none; /* Hide only the controls and fretboard */
  }
 
  .rotate-screen {
    display: flex;
  }.try{padding:10px; background-color:rgb(221, 255, 72); color:#000;} 
  .copy{top:0px !important;}
}

/* CSS to adjust layout for landscape mode on mobile devices */
@media only screen and (max-device-width: 866px) and (orientation: landscape) {
  .App {
    padding: 10px;
  }
  div.scale-info.metronome-display, div.scale-info.playnote-display, div.scale-info.playnow-display, div.lower-controls {display:none;}
  h1 {
    font-size: 28px; 
  }
  h2 {
    font-size: 20px; line-height: 20px;
  }
  img.logo {
    max-width: 80px; /* Adjust size */
    top:10px; 
    position:relative;
  
  }
  .rotate-screen {
    display: none;
  }

  .logo-container {
    justify-content: flex-start; /* Align logo and text to the right */
 
  }
.playcontrols{margin-top: -375px !important; margin-left:200px !important;}
  .playcontrols-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align controls to the left */
  }
}

/* Ensure the app fits the screen */
html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.app-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landscape-message {
  display: none;
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: #000;
  color: #fff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.controls{z-index:10000}
.copy{top: 290px; position:relative;}
.ios-install-instructions {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.instructions-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 300px;
  width: 90%;
}

.share-icon {
  width: 24px;
  vertical-align: middle;
  margin-left: 5px;
}

.arrow-icon {
  width: 30px;
  margin-top: 10px;
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.close-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}

.close-button:hover {
  background: #0056b3;
}
/* LoadingBar.css */
.loading-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  z-index: 1000;
  background-color: transparent;
}

.loading-bar {
  width: 100%;
  height: 100%;
  background-color: #007bff;
  animation: loading 3s linear infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}