/* Inside src/fonts.css */
@font-face {
    font-family: 'Saira Condensed Medium';
    src: url('./fonts/Saira_Condensed-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Saira Condensed Light';
    src: url('./fonts/Saira_Condensed-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }