.fret {
  flex: 1;
  height: 40px;
  border-right: 1px solid #3f3f3f;
  margin-right: 1px;
  position: relative;
  transition: background-color 0.3s ease;
}

.fret:last-child {
  margin-right: 0;
}

.fret-note {
  position: absolute;
  top: 50%; /* Center vertically within the fret */
  left: 50%; /* Center horizontally within the fret */
  transform: translate(-50%, -50%); /* Adjust position to center text */
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  display: none; 
  z-index: 2; 
}

/* Circle around the note text */
.fret-note::before {
  content: '';
  position: absolute;
  top: 50%; 
  left: 50%; 
  width: 32px; 
  height: 32px; 
  background-color: orange; 
  border-radius: 50%; 
  transform: translate(-50%, -50%); 
  z-index: -1; 
}
/* Apply styles to open notes */
.open-note .fret-note {
  color: #fff; 
}
div.fret.open-note{
  width:30px;
  background-color: #666;
  border-right: 10px solid #ffffff;
}
/* Apply styles to fretted notes */
.fretted-note .fret-note {
  color: #000000; 
}
.fret.show-notes .fret-note,
.fret.open-note .fret-note {
  display: block;
  color: rgb(255, 255, 255);
}

.fret.open-note .fret-note::before,
.fret.show-notes .fret-note::before {
  width: 32px; 
  height: 32px;
  background-color: orange; 
}

.fret.open-note:hover .fret-note::before,
.fret.show-notes:hover .fret-note::before {
  background-color: rgb(39, 156, 10); 
}

.fret.root-note .fret-note {
  color: #fff; 
}

.fret.root-note .fret-note::before {
  background-color: red; 
}

.fret:hover .fret-note::before,
.fret.playing-note .fret-note::before {
  background-color: rgb(39, 156, 10); 
}

/* Specific styles for landscape mode */
@media only screen and (max-device-width: 866px) and (orientation: landscape) {
  
  .fret.open-note .fret-note::before,
.fret.show-notes .fret-note::before {
  width: 22px; 
  height: 22px;
  background-color: orange; 
}
.fret-note {
    font-size: 10px; 
  }
  
  .fret-note::before {
    width: 20px; 
    height: 20px; 
  }
  div.fret.open-note{

    border-right: 5px solid #ffffff;
  }
}
